import {
    usePushNotificationsAction,
    usePushNotificationsRegistration,
} from "./usePushNotificationsAction";
import useInboxNotificationsListener from "./useInboxNotificationsListener";
import { NotificationPermissionsProvider } from "./permissions";
import { ParentProps } from "solid-js";

export function NotificationsProvider(props: ParentProps) {
    usePushNotificationsRegistration();
    usePushNotificationsAction();
    useInboxNotificationsListener();

    return <NotificationPermissionsProvider>{props.children}</NotificationPermissionsProvider>;
}
