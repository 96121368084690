import { Accessor, createContext, createResource, ParentProps, Setter, Show } from "solid-js";
import { localeByCode, LanguageCode } from "./localeByCode";
import { Locale } from "./Locale";
import { useRequiredContext } from "../../utils/solidjs";
import { Device } from "@capacitor/device";

const I18nContext = createContext<
    [
        Accessor<Locale>,
        {
            languageCode: Accessor<LanguageCode>;
            setLanguageCode: Setter<LanguageCode>;
        },
    ]
>();

export function I18nProvider(props: ParentProps) {
    const [languageCode, { mutate: setLanguageCode }] = createResource(fetchLanguageCode);

    return (
        <Show when={languageCode()}>
            {languageCode => {
                const locale = () => localeByCode[languageCode()];
                return (
                    <I18nContext.Provider
                        value={[
                            locale,
                            {
                                languageCode,
                                setLanguageCode: setLanguageCode as Setter<LanguageCode>,
                            },
                        ]}
                    >
                        {props.children}
                    </I18nContext.Provider>
                );
            }}
        </Show>
    );
}

export const useLocale = () => useRequiredContext(I18nContext, "useLocale", "I18nProvider");

async function fetchLanguageCode(): Promise<LanguageCode> {
    const { value } = await Device.getLanguageCode();
    if (value in localeByCode) {
        return value as LanguageCode;
    } else {
        return "en";
    }
}
