import { Match, Switch } from "solid-js";
import { useNavigate } from "@solidjs/router";
import { Capacitor } from "@capacitor/core";
import { createCurrentOrganizationQuery } from "../../api/services/organization/queries";

export default function CurrentOrganizationLogo() {
    const currentOrganizationQuery = createCurrentOrganizationQuery();
    const n = useNavigate();

    const navigate = () => {
        if (Capacitor.isNativePlatform()) {
            n("/home");
        } else {
            window.location.href = "/home";
        }
    };
    return (
        // reload home instead of navigate("/home") so the user feels the button does something
        <button onClick={() => navigate()} class="flex items-center">
            <Switch>
                <Match
                    when={currentOrganizationQuery.isLoading || currentOrganizationQuery.isError}
                >
                    <div class="h-16 w-32" />
                </Match>
                <Match when={currentOrganizationQuery.data}>
                    {organization => <img src={organization().logoUrl} alt="" class="h-16" />}
                </Match>
            </Switch>
        </button>
    );
}
