import {
    BackendOrganization,
    deserializeOrganization,
    FrontOrganization,
    OrganizationService,
    sOrganization,
} from "../interface";
import myCompanyLogo from "../../../../assets/imgs/logo-mycompany-default.png";
import { sleep } from "../../../../utils/mocks";
import { makeServiceQuery } from "../../../utils";
import { nullable } from "superstruct";
import { getUserOr401 } from "../../../../modules/auth/authContext";
import { mockSignInMethod, SignInMethodType } from "../signInMethods";

export class MockOrganizationService implements OrganizationService {
    getCurrentOrganization = async (): Promise<FrontOrganization> => {
        const user = await getUserOr401();
        const organization = mockOrganizations.find(
            org => org.firebase_tenant_id === user.firebaseTenantId,
        );
        if (!organization)
            throw new Error(
                `Mock organization with firebaseTenantId ${user.firebaseTenantId} not found`,
            );
        return deserializeOrganization(organization);
    };

    getOrganizationBySubdomain = makeServiceQuery({
        fetchJson: async (subdomain: string) => {
            await sleep(1000);
            return mockOrganizations.find(org => org.subdomain === subdomain) ?? null;
        },
        responseSchema: nullable(sOrganization()),
        deserialize: json => (json ? deserializeOrganization(json) : null),
    });
}

export const mockOrganizations: BackendOrganization[] = [
    {
        id: 1,
        name: "MyCompany",
        subdomain: "mycompany",
        logo_url: myCompanyLogo,
        firebase_tenant_id: "mycompany-5nsfv",
        sign_in_methods: [
            SignInMethodType.GOOGLE,
            SignInMethodType.MAGIC_LINK,
            SignInMethodType.PASSWORD,
        ].map(mockSignInMethod),
    },
    {
        id: 2,
        name: "Tiendas Neto",
        subdomain: "tiendasneto",
        logo_url: "https://tiendasneto.com.mx/media/logo/stores/1/Logo_TiendasNeto.png",
        firebase_tenant_id: "tiendasneto-a6rc5",
        sign_in_methods: [SignInMethodType.PASSWORD].map(mockSignInMethod),
    },
    {
        id: 3,
        name: "Danhos",
        subdomain: "danhos",
        logo_url: "https://fibradanhos.com.mx/img/logo.png",
        firebase_tenant_id: null,
        sign_in_methods: [SignInMethodType.PASSWORD].map(mockSignInMethod),
    },
];
