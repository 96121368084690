export type InboxNotification = {
    id: string;
    title: string;
    createdAt: Temporal.Instant;
    read: boolean;
} & (
    | {
          type:
              | NotificationType.ACTIVITY
              | NotificationType.INCIDENT
              | NotificationType.POST
              | NotificationType.REPORT
              | NotificationType.SYSTEM_UPDATE
              | NotificationType.TEST;
      }
    | {
          type: NotificationType.WORKFLOW_ACTIVITY;
          executionId: string;
      }
);

export enum NotificationType {
    ACTIVITY,
    INCIDENT,
    POST,
    REPORT,
    SYSTEM_UPDATE,
    WORKFLOW_ACTIVITY,
    /** We are just testing the notifications work!
     * If pressed, this notification is just mark as read.
     */
    TEST,
}
