import "./RichTextRender.css";
import { createRef } from "../reactRefs";
import { createEffect, createResource, Show } from "solid-js";
import { filterXSS } from "xss";

export default function RichTextRender(props: { text: string }) {
    const [md] = createResource(getMarkdownitInstance);
    return (
        <Show when={md()}>
            {md => (
                <div class="rich-text">
                    <Sanitized html={md().render(props.text)} />
                </div>
            )}
        </Show>
    );
}

let lazyInit: import("markdown-it");
async function getMarkdownitInstance() {
    return (lazyInit ??= (await import("markdown-it")).default({ linkify: true }));
}

export function Sanitized(props: { html: string }) {
    const ref = createRef<HTMLSpanElement>();

    createEffect(() => {
        ref.current!.innerHTML = filterXSS(props.html);
    });

    return <span ref={ref} />;
}
