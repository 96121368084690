// noinspection JSUnusedGlobalSymbols,JSUnresolvedReference

import { InfiniteData, createMutation } from "@tanstack/solid-query";
import { getApiInstance } from "../../index";
import { Paginated, createApiMutation, queryClient } from "../../utils";
import { InboxNotification } from "../../../modules/notifications/frontendModels";
import { RegisterDeviceParams } from "./interface";

export function createDeleteNotificationMutation() {
    const api = getApiInstance();

    return createMutation(() => ({
        mutationKey: ["deleteNotification"],
        mutationFn: async (id: string): Promise<void> => {
            await api.notifications.deleteNotification(id);
        },
        onMutate: async (id: string) => {
            await queryClient.cancelQueries({ queryKey: ["inbox"] });
            const prevNotifications = queryClient.getQueryData<
                InfiniteData<Paginated<InboxNotification>>
            >(["inbox"])!;
            queryClient.setQueryData(["inbox"], (old: InfiniteData<Paginated<InboxNotification>>) =>
                mapInfiniteData(old, page =>
                    filterPaginated(page, notification => notification.id !== id),
                ),
            );
            return { prevNotifications };
        },
        onError: (_err, _id, context) => {
            queryClient.setQueryData(["inbox"], context!.prevNotifications);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["inbox"] });
        },
    }));
}

export const createMarkAsReadMutation = () => {
    const api = getApiInstance();

    return createMutation(() => ({
        mutationKey: ["markAsRead"],
        mutationFn: async (id: string): Promise<void> => {
            await api.notifications.markAsRead(id);
        },
        onMutate: async (id: string) => {
            await queryClient.cancelQueries({ queryKey: ["inbox"] });
            const prevNotifications = queryClient.getQueryData<
                InfiniteData<Paginated<InboxNotification>>
            >(["inbox"])!;
            queryClient.setQueryData(["inbox"], (old: InfiniteData<Paginated<InboxNotification>>) =>
                mapInfiniteData(old, page =>
                    mapPaginated(page, notification => {
                        if (notification.id === id) {
                            return {
                                ...notification,
                                read: true,
                            };
                        }
                        return notification;
                    }),
                ),
            );
            return { prevNotifications };
        },
        onError: (_err, _id, context) => {
            queryClient.setQueryData(["inbox"], context!.prevNotifications);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["inbox"] });
        },
    }));
};

function mapInfiniteData<T, U>(
    data: InfiniteData<T>,
    fn: (page: T, index: number, pages: T[]) => U,
): InfiniteData<U> {
    return {
        pageParams: data.pageParams,
        pages: data.pages.map(fn),
    };
}

function mapPaginated<T, U>(
    paginated: Paginated<T>,
    fn: (result: T, index: number, results: T[]) => U,
): Paginated<U> {
    return {
        count: paginated.count,
        results: paginated.results.map(fn),
    };
}

function filterPaginated<T>(
    paginated: Paginated<T>,
    predicate: (result: T, index: number, results: T[]) => boolean,
): Paginated<T> {
    const results = paginated.results.filter(predicate);
    return {
        count: results.length,
        results,
    };
}

export function createRegisterDeviceMutation() {
    return createApiMutation(api => ({
        mutationKey: ["registerDevice"],
        mutationFn: (params: RegisterDeviceParams) => api.notifications.registerDevice(params),
    }));
}

export function createUnregisterDeviceMutation() {
    return createApiMutation(api => ({
        mutationKey: ["unregisterDevice"],
        mutationFn: (deviceToken: string) => api.notifications.unregisterDevice(deviceToken),
    }));
}
