import {
    invalidColorClass,
    invalidIcon,
    staleColorClass,
    validationIconSizeClass,
    validColorClass,
    validIcon,
} from "../../presentation";
import { FieldController } from "../../state";
import { ParentProps } from "solid-js";

export function ValidationIconWrapper(
    props: ParentProps<{ field: FieldController<unknown>; inputParentClass?: string }>,
) {
    const validation = () => props.field.lastValidation;

    const justTheIcon = () => (validation()?.error ? invalidIcon : validIcon);
    const naiveColorClass = () => (validation()?.error ? invalidColorClass : validColorClass);
    const colorClass = () => (validation()?.stale ? staleColorClass : naiveColorClass());
    const icon = () => `${justTheIcon()} ${colorClass()} ${validationIconSizeClass}`;

    return (
        <InputWrapper icon={validation() ? icon() : ""} inputParentClass={props.inputParentClass}>
            {props.children}
        </InputWrapper>
    );
}

export function InputWrapper(props: ParentProps<{ icon?: string; inputParentClass?: string }>) {
    return (
        <div class={`relative w-full ${props.inputParentClass}`}>
            {props.children}
            <i class={`${props.icon} absolute right-3 top-1/2 -translate-y-1/2 transform`} />
        </div>
    );
}
