import { Struct, literal, string, type, union } from "superstruct";

type NewPostNotification = {
    type: "NEW_POST";
    postId: string;
    title: string;
};

export type PushNotificationUnion = NewPostNotification;

export function sPushNotification(): Struct<PushNotificationUnion> {
    return union([
        type({
            type: literal("NEW_POST"),
            postId: string(),
            title: string(),
        }),
    ]);
}
