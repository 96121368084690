/// <reference types="vite/client" />

import { create, StructError } from "superstruct";
import { EnvSchema } from "../env.schema";

export const parsedEnv = parseEnvVars();

function parseEnvVars() {
    try {
        // eslint-disable-next-line no-restricted-syntax
        return create(import.meta.env, EnvSchema);
    } catch (error) {
        if (error instanceof StructError) {
            const e = Error(`Error parsing environment variables: ${error.message}`);
            // eslint-disable-next-line no-restricted-syntax
            console.debug(import.meta.env);
            alert(e);
            throw e;
        } else {
            throw error;
        }
    }
}
