import { fireAndForget } from "./async";
import { parsedEnv } from "./parsedEnv";
import { showErrorToast } from "./errorHandling";
import { ErrorBoundary, onMount, ParentProps } from "solid-js";
import { GenericSuspenseFallback } from "../modules/ui/skeletons";
import { useThrowToErrorBoundary } from "./solidjs";
import JsonStorage from "./JsonStorage";
import { boolean, string, type } from "superstruct";
import { sDateTimeString } from "../api/utils";

/** Sometimes a JavaScript chunk fails to load lazily because a new version was
 * deployed while the old version was being used (or the browser cache was
 * invalidated too late). That would result in a crash, so this handles that
 * case in a more graceful way.
 */
export default function ChunkLoadErrorBoundary(props: ParentProps) {
    type Failure = { timestamp: string; appVersion: string; apologized: boolean };
    const storage = JsonStorage<Failure>(
        "latestChunkLoadFailure",
        type({ timestamp: sDateTimeString(), appVersion: string(), apologized: boolean() }),
    );
    const isRecent = (failure: Failure) =>
        Temporal.Instant.from(failure.timestamp).until(Temporal.Now.instant()).minutes < 5;

    const throwToParentErrorBoundary = useThrowToErrorBoundary();
    const handleChunkLoadError = async (error: Error): Promise<void> => {
        // Crash if an infinite loop is detected
        const failure = await storage.get();
        if (failure && isRecent(failure)) {
            return throwToParentErrorBoundary(error);
        }

        // Reload the page and hope it's a new version, so take note of the current version
        await storage.set({
            appVersion: parsedEnv.VITE_PACKAGE_JSON_VERSION,
            timestamp: Temporal.Now.instant().toString(),
            apologized: false,
        });
        location.reload();
    };

    // Apologize or explain to the user if the page was reloaded
    onMount(async () => {
        const failure = await storage.get();
        if (failure && isRecent(failure) && !failure.apologized) {
            showErrorToast(
                parsedEnv.VITE_PACKAGE_JSON_VERSION !== failure.appVersion
                    ? "Disculpa la interrupción, hubo que actualizar a la última versión de AIM Manager X."
                    : "Disculpa la interrupción, tuvimos un problema técnico.",
            );
            await storage.set(prev => ({ ...prev!, apologized: true }));
        }
    });

    const isChunkLoadError = (error: unknown) =>
        error instanceof Error &&
        error.message.match(/(error loading|failed to fetch) dynamically imported module/i);
    return (
        <ErrorBoundary
            fallback={error => {
                if (isChunkLoadError(error)) {
                    fireAndForget(handleChunkLoadError(error));
                    // The page will reload, so better render a loading than a BSoD
                    return <GenericSuspenseFallback />;
                } else throw error;
            }}
        >
            {props.children}
        </ErrorBoundary>
    );
}
