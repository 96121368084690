import { create, Describe, Struct, StructError } from "superstruct";
import { fetchDataFromMockBackend } from "./backendMocks";
import { parsedEnv } from "../../utils/parsedEnv";
import { isDjangoDebug500, Json } from "../../api/utils";
import { firebaseAuthService } from "../../api/services/auth/implementations/firebase";
import { isAuthenticated, revokeSession } from "../auth/authContext";
import { apiGateway } from "../../api/apiGateway";

/** Used to modify the request and/or add custom logic like refresh token.
 * You can mutate the request directly without problems. */
export type Interceptor = (
    request: Request,
    fetchData: (request: Request) => Promise<Json | Blob | undefined>,
    originalResource: string,
) => Promise<Json | Blob | undefined>;

export const noopInterceptor: Interceptor = (request, fetchData) => fetchData(request);

export class Client {
    constructor(
        readonly urlPrefix: string,
        private readonly options?: Partial<{
            readonly mock: boolean;
            readonly intercept: Interceptor;
        }>,
    ) {}

    get(path: string): RequestBuilder {
        return this.makeRequestBuilder("GET", path);
    }

    post(path: string): RequestBuilder {
        return this.makeRequestBuilder("POST", path);
    }

    put(path: string): RequestBuilder {
        return this.makeRequestBuilder("PUT", path);
    }

    patch(path: string): RequestBuilder {
        return this.makeRequestBuilder("PATCH", path);
    }

    delete(path: string): RequestBuilder {
        return this.makeRequestBuilder("DELETE", path);
    }

    private makeRequestBuilder(method: string, path: string) {
        return new RequestBuilder({
            method,
            path,
            urlPrefix: this.urlPrefix,
            intercept: this.options?.intercept ?? noopInterceptor,
            ...this.options,
        });
    }
}

// urlJoin("https://", "www.example.com/", "/api/foo/") === "https://www.example.com/api/foo/"
export function urlJoin(...parts: string[]): string {
    return parts
        .filter(part => part !== "")
        .reduce((acc, part) => acc.replace(/\/$/, "") + "/" + part.replace(/^\//, ""));
}

class RequestBuilder {
    constructor(
        private readonly options: {
            readonly method: string;
            readonly urlPrefix: string;
            readonly path: string;
            readonly mock?: boolean;
            readonly intercept: Interceptor;
        },
    ) {}

    private currentQueryParams: Record<string, string | number> = {};
    private currentHeaders = new Headers();
    private currentBodyInit: BodyInit | undefined = undefined;

    query(params: Record<string, string | number> | undefined): this {
        if (params) Object.assign(this.currentQueryParams, params);
        return this;
    }

    headers(headers: HeadersInit): this {
        assignHeaders(this.currentHeaders, headers);
        return this;
    }

    sendFormUrlEncoded(body: Record<string, string | string[]>): this {
        assignHeaders(this.currentHeaders, { "Content-Type": "application/x-www-form-urlencoded" });
        const params = new URLSearchParams();

        for (const [key, value] of Object.entries(body)) {
            if (Array.isArray(value)) {
                value.forEach(item => params.append(key, item));
            } else {
                params.append(key, value);
            }
        }

        this.currentBodyInit = params;
        return this;
    }

    sendFormData(body: Record<string, string | File>): this {
        const formData = new FormData();
        Object.entries(body).forEach(([key, value]) => {
            formData.append(key, value);
        });
        this.currentBodyInit = formData;
        return this;
    }

    sendJson<TBody extends Json>(body: TBody): this {
        assignHeaders(this.currentHeaders, { "Content-Type": "application/json" });
        this.currentBodyInit = JSON.stringify(body);
        return this;
    }

    async receive<TResponseBody>(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        responseSchema: Struct<TResponseBody, any>,
    ): Promise<TResponseBody> {
        const { request, responseBody } = await this.fetch();
        return validateResponse<TResponseBody>(responseBody, responseSchema, { request });
    }

    /** Fetch the request, assuming the response will be a Blob.
     * Throws an Error on a non-blob response. */
    async receiveBlob(): Promise<Blob> {
        const { request, responseBody } = await this.fetch(true);
        if (!(responseBody instanceof Blob))
            throw new Error(
                `${request.method} ${request.url} returned non-blob response: ${JSON.stringify(
                    responseBody,
                    null,
                    4,
                )}`,
            );
        return responseBody;
    }

    /** Fetch the request, assuming the response will be JSON.
     * Throws an Error on an empty response.
     * @remarks - To simplify the implementation, non-empty responses
     * that are also non-JSON are returned as a string. */
    async receiveJson(): Promise<Json> {
        const { request, responseBody } = await this.fetch();
        if (responseBody === undefined)
            throw new Error(`${request.method} ${request.url} returned empty response.`);
        return responseBody as Json;
    }

    /** Fetch the request, assuming the response will be empty.
     * Triggers a warning on a non-empty response. */
    async receiveNothing(): Promise<void> {
        const { request, responseBody } = await this.fetch();
        if (responseBody)
            console.warn(
                `${request.method} ${request.url} actually returned a non-empty response.`,
            );
    }

    private async fetch(
        isBlob?: true,
    ): Promise<{ request: Request; responseBody: Json | Blob | undefined }> {
        const originalResource = makeAbsoluteUrl(
            this.options.urlPrefix,
            this.options.path,
            this.currentQueryParams,
        );
        const request = new Request(originalResource, {
            method: this.options.method,
            headers: this.currentHeaders,
            body: this.currentBodyInit,
        });
        const responseBody = await this.options.intercept(
            request,
            request => fetchData(request, !!this.options.mock, !!isBlob),
            originalResource,
        );
        return { request, responseBody };
    }
}

/** Like Object.assign, but for Headers. Mutates `target`. */
export function assignHeaders(target: Headers, source: HeadersInit | undefined): void {
    new Headers(source).forEach((value: string, key: string) => {
        target.set(key, value);
    });
}

/** Validates the `responseData` against the `responseSchema`.
 * If valid, returns the `responseData` cast to the appropriate type.
 * If invalid, throws a `StructError`.
 * The `context` parameter is used to provide debug info to the developer. */
function validateResponse<TResponseData>(
    responseData: unknown,
    responseSchema: Struct<TResponseData> | Describe<TResponseData>,
    context: { request: Request },
): TResponseData {
    try {
        return create(responseData, responseSchema);
    } catch (error) {
        if (error instanceof StructError) {
            console.error(error);
            console.debug({ request: context.request, responseData });
        }
        throw error;
    }
}

function makeAbsoluteUrl(
    urlPrefix: string,
    path: string,
    queryParams: Record<string, string | number>,
): string {
    const queryString = makeQueryString(queryParams);
    let url = urlJoin(urlPrefix, path);
    // Note: if the url ends with /, using /api/endpoint/?query=param also works.
    if (queryString) url += "?" + queryString;
    return url;
}

export function makeQueryString(queryParams: Record<string, unknown>): string {
    return Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(`${value}`)}`)
        .join("&");
}

/** fetch, but returns just the data (e.g. JSON) instead of the whole Response object. */
async function fetchData(
    request: Request,
    mock: boolean,
    isBlob: boolean,
): Promise<Json | Blob | undefined> {
    if (mock) {
        return await fetchDataFromMockBackend(request);
    } else {
        return await fetchDataFromRealBackend(request, isBlob);
    }
}

async function fetchDataFromRealBackend(
    request: Request,
    isBlob: boolean,
): Promise<Json | Blob | undefined> {
    const response = await fetch(request);
    const body = await parseResponse(response, isBlob);
    if (!response.ok) {
        console.debug({
            requestHeaders: Object.fromEntries(request.headers.entries()),
            responseBody: body,
        });
        throw new BadResponseError(
            `[${response.status}] ${request.method} ${request.url} ${JSON.stringify(
                body,
                null,
                4,
            )}`,
            response,
            body,
        );
    }

    return body;
}

/** Returns the response body as an object if possible, specifically:
 * - a plain object, if the response is JSON,
 * - undefined, if the response is empty,
 * - or a string, if the response has non-JSON content (e.g. HTML).
 */
async function parseResponse(
    response: Response,
    isBlob: boolean,
): Promise<Json | Blob | undefined> {
    if (isBlob) {
        return await response.blob();
    }

    const text = await response.text();
    try {
        return text ? JSON.parse(text) : undefined;
    } catch (error) {
        if (error instanceof SyntaxError) return text;
        else throw error;
    }
}

export class BadResponseError extends Error {
    constructor(
        message: string,
        public readonly response: Response,
        public readonly body: unknown,
    ) {
        super(message);
        Object.setPrototypeOf(this, BadResponseError.prototype);
        Error.captureStackTrace?.(this, BadResponseError);
        this.name = this.constructor.name;
    }
}

export class EmailAlreadyInUseError extends Error {
    constructor() {
        super("Email already in use");
        Object.setPrototypeOf(this, EmailAlreadyInUseError.prototype);
        Error.captureStackTrace?.(this, EmailAlreadyInUseError);
        this.name = this.constructor.name;
    }
}
/** The top-level domain of the current environment. */
export const tld: "red" | "blue" | "pink" | "io" = "red";
export const atlasClient = new Client("/api", {
    mock: parsedEnv.VITE_MOCK_ATLAS,
    intercept: async (request, fetchData) => {
        if (await isAuthenticated())
            assignHeaders(request.headers, {
                Authorization: `Bearer ${await firebaseAuthService.getIdToken()}`,
                ...atlasDummyHeaders,
            });
        return fetchData(request);
    },
});

/** Some Atlas endpoints arbitrarily require some headers to be present even
 * if they seem to be unused. Instead of having to memorize which endpoints
 * require which headers, let's send every header that may be needed on every
 * request to Atlas. Not sending the required headers makes Atlas to respond
 * with error 400. */
const atlasDummyHeaders = {
    "x-aim-username": "",
    "x-aim-account-host": "",
    "x-aim-user-actions": "",
};

export const workflowClient = new Client(parsedEnv.VITE_SERVER_WORKFLOW, {
    intercept: apiGateway(async (request, fetchData) => {
        if (await isAuthenticated())
            assignHeaders(request.headers, {
                Authorization: `Bearer ${await firebaseAuthService.getIdToken()}`,
            });

        try {
            return await fetchData(request);
        } catch (error) {
            // KeyError 'passport' probably means the user has account on firebase but not on the backend
            if (isDjangoDebug500(error, "KeyError", "'passport'")) return revokeSession();

            throw error;
        }
    }),
});
