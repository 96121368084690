import { ParentProps, Show } from "solid-js";
import Accordion, { AccordionItem } from "../modules/ui/Accordion";
import { useLocale } from "../modules/i18n/context";

// eslint-disable-next-line @typescript-eslint/ban-types
type NonFunction<T> = T extends Function ? never : T;

/** Renders prettified JSON for debugging purposes.
 *
 * @remarks - Doesn't let you pass a function to avoid mistakes, see the examples.
 *
 * @example
 * // ❌ Wrong
 * <Show when={...}>
 *     {accessor => <JsonDebug value={accessor} />
 * </Show>
 *
 * // ✅ Right
 * <Show when={...}>
 *     {accessor => <JsonDebug value={accessor()} />
 * </Show>
 */
export function JsonDebug<T>(props: { value: NonFunction<T> }) {
    // Show a placeholder instead of crashing if a cyclic object is passed
    const placeholder = Math.random().toString();
    function cyclicReplacer() {
        const seen = new WeakSet();
        return (_key: string, value: unknown) => {
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    return placeholder;
                }
                seen.add(value);
            }
            return value;
        };
    }

    return (
        <pre class="overflow-x-auto">
            <Show when={props.value !== undefined} fallback="undefined">
                {JSON.stringify(props.value, cyclicReplacer(), 4).replaceAll(
                    `"${placeholder}"`,
                    "...",
                )}
            </Show>
        </pre>
    );
}

/** Shows an expandable technical details section that starts collapsed. */
export function TechnicalDetails(props: ParentProps) {
    const [locale] = useLocale();

    return (
        <Accordion>
            <AccordionItem
                summary={locale().utils.showTechnicalDetails}
                summaryOpen={locale().utils.hideTechnicalDetails}
            >
                {props.children}
            </AccordionItem>
        </Accordion>
    );
}
