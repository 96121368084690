import { useWindowEventListener } from "./solidjs";

/** Runs the provided function on a double Shift keypress. */
export default function useDoubleShiftListener(listener: (event: KeyboardEvent) => void) {
    let lastShiftTime = 0;
    const doubleShiftInterval = 500; // Time in milliseconds within which a double Shift is detected

    useWindowEventListener("keydown", event => {
        if (event.key === "Shift") {
            const currentTime = new Date().getTime();
            if (currentTime - lastShiftTime <= doubleShiftInterval) {
                listener(event);
                lastShiftTime = 0;
            } else {
                lastShiftTime = currentTime;
            }
        }
    });
}
