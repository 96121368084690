import { Struct, enums } from "superstruct";

export enum InputTypeEnum {
    text = "text",
    multiple_choice = "multiple_choice",
    date = "date",
    datetime = "datetime",
    time = "time",
    boolean = "boolean",
    integer = "integer",
    float = "float",
    decimal = "decimal",
    voice_message = "voice_message",
    files = "files",
    code = "code",
    date_range = "date_range",
    checkboxes = "checkboxes",
    num_range = "num_range",
    value_in_num_range = "value_in_num_range",
    stars = "stars",
    gps = "gps",
    venue = "venue",
}
export type InputTypeEnumOptions = keyof typeof InputTypeEnum;
export function sInputEnum(): Struct<InputTypeEnum> {
    return enums(Object.values(InputTypeEnum));
}
