import { Button, GargamelButtonProps } from "../ui/components";
import { useFormState } from "./state";

export type SubmitButtonProps = Omit<
    GargamelButtonProps,
    "type" | "onClick" | "pending" | "pendingText"
> & {
    /** If provided, the button text changes to this when the form is
     * submitting. */
    submittingText?: string;
};

/** Used in tandem with FormWrapper to have a submit button that shows a
 * spinner and is disabled when the form is submitting, so the user knows they
 * should wait and clicking again is not needed.
 *
 * @remarks The `type="submit"` is automatically handled. The onClick is not
 * needed as we have `<FormWrapper onSubmit={...}>`. */
export default function SubmitButton(props: SubmitButtonProps) {
    const { submitting, state } = useFormState();

    return (
        <Button
            type={"submit"}
            pending={submitting()}
            pendingText={props.submittingText}
            {...props}
            disabled={state.readOnly() || props.disabled}
        />
    );
}
