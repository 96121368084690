import _ from "lodash";
import { cloneFormValues } from "./utils";

type Path = string[];
type FieldName = string;

/** Given the form values and a `fn` that mutates them, return the `name`s of the affected fields. */
export function diff(
    formValues: Record<string, unknown>,
    fn: (obj: Record<string, unknown>) => void,
): Set<FieldName> {
    const changes = Array<Path>();
    fn(new Proxy(formValues, Traps([])));
    return new Set(changes.map(toFieldName));

    // Inspired by https://github.com/solidjs/solid/blob/main/packages/solid/store/src/modifiers.ts#L135
    function Traps(path: Path): ProxyHandler<Record<string, unknown>> {
        return {
            get(target, property: string, receiver) {
                if (property === "prototype") return target.prototype; // [1]
                const value = Reflect.get(target, property, receiver);
                return _.isObject(value) ? new Proxy(value, Traps([...path, property])) : value;
            },
            set(target, property: string, value, receiver) {
                report(target, property, value);
                // Use cloneFormValues because of this https://runkit.com/robin40/6646c41776eeb40008d2dbf5
                return Reflect.set(target, property, cloneFormValues(value), receiver);
            },
            deleteProperty(target, property: string) {
                report(target, property, target[property]);
                return Reflect.deleteProperty(target, property);
            },
        };

        function report(target: object, property: string, value: unknown): void {
            if (_.isObject(value)) {
                changes.push(...walk(value, [...path, property]));
            } else if (_.isArray(target) && property === "length") {
                changes.push(path);
            } else {
                changes.push([...path, property]);
            }
        }
    }

    /* [1] This fixes the following error:
     *     "TypeError: 'get' on proxy: property 'prototype' is a read-only and non-configurable data property on the
     *      proxy target but the proxy did not return its actual value (expected '[object Array]'
     *      but got '[object Array]')."
     *     that appears when doing `form.setValues(values => values.array.splice(...))` or `.filter`.
     */
}

/** Generates every path in `value`, assuming that the path of `value` itself is `path`.
 *
 * @example
 * `walk({ arr: [42, 43] }, ["obj"])` will generate
 * - ["obj", "arr", "0"]
 * - ["obj", "arr", "1"]
 * - ["obj", "arr"]
 * - ["obj"]
 */
function* walk(value: unknown, path: Path): Generator<Path> {
    if (_.isObject(value)) {
        for (const [k, v] of Object.entries(value)) {
            yield* walk(v, [...path, k]);
        }
    }
    yield path;
}

/** toFieldName(["obj", "arr", "0"]) === "obj.arr[0]" */
function toFieldName(path: Path): FieldName {
    if (_.isEmpty(path)) throw new Error("Empty path");
    if (path.length === 1) return path[0];
    const last = _.last(path)!;
    const prev = path.slice(0, -1);
    return isNaN(+last) ? `${toFieldName(prev)}.${last}` : `${toFieldName(prev)}[${last}]`;
}
