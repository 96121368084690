import { useLocale } from "../i18n/context";
import { A, useNavigate } from "@solidjs/router";
import { OnClickAnchor } from "../../utils/solidjs";

export default function SettingsTab(props: { onClick?: OnClickAnchor }) {
    const [locale] = useLocale();

    return (
        <A
            href="/settings"
            aria-label={locale().settings.title}
            class="center-items h-16 w-16 text-dark-gray-400"
            onClick={props.onClick}
        >
            <i class="fas fa-cog text-md lg:text-xl" />
        </A>
    );
}

export function SettingsButton() {
    const [locale] = useLocale();
    const navigate = useNavigate();

    return (
        <button
            onClick={() => navigate("/settings")}
            class="flex items-center gap-2 px-3 text-primary-700/80"
        >
            <i class="fas fa-cog" />
            {locale().settings.title}
        </button>
    );
}
