import { type, literal, integer, Infer, array, union } from "superstruct";
import { emailRegex } from "../forms/fields/EmailField";

//VALIDATORS
export type ValidatorUnion = MinLenValidator | EmailValidator;

//MinLenValidator
export function sMinLenValidator() {
    return type({
        type: literal("min_length"),
        limit_value: integer(),
    });
}

export function sEmailValidator() {
    return type({
        type: literal("email"),
    });
}
export function sValidatorArrayUnion() {
    return array(union([sMinLenValidator(), sEmailValidator()]));
}
export type MinLenValidator = Infer<ReturnType<typeof sMinLenValidator>>;
export type EmailValidator = Infer<ReturnType<typeof sEmailValidator>>;

export function getValidationFunction<T>(validators: ValidatorUnion[]): (val: T) => true | string {
    const validatorFunctions = validators.map(validator => {
        switch (validator.type) {
            case "min_length":
                return (val: string) =>
                    val.length >= validator.limit_value ||
                    `El campo debe tener al menos ${validator.limit_value} caracteres`;

            case "email":
                return (val: string) =>
                    emailRegex.test(val) || "El campo debe ser un correo electrónico válido";
            default:
                throw new Error(`Unknown validator type: ${JSON.stringify(validator)}`);
        }
    });

    return (val: T) => {
        console.log({ val, validators });
        for (const validator of validatorFunctions) {
            const result = validator(val as string);
            if (result !== true) {
                return result;
            }
        }
        return true;
    };
}
