import { initializeApp } from "firebase/app";

initializeApp({
    apiKey: "AIzaSyDZi-9bOPBkgIj9L93CZU08d4LgbkUKtzc",
    authDomain: "atlas-dev-mycompany.firebaseapp.com",
    projectId: "atlas-dev-mycompany",
    storageBucket: "atlas-dev-mycompany.appspot.com",
    messagingSenderId: "643204901253",
    appId: "1:643204901253:web:4d8203b36f1cf2c042bcec",
    measurementId: "G-GCTBMQ6CR7",
});
