import { array, Describe, integer, nullable, optional, string, type } from "superstruct";
import { ServiceQuery } from "../../utils";
import { SignInMethod as FirebaseSignInMethod } from "@firebase/auth";
import {
    BackendSignInMethod,
    FrontSignInMethod,
    SignInMethodType,
    sSignInMethod,
} from "./signInMethods";
import { parsedEnv } from "../../../utils/parsedEnv";

export interface OrganizationService {
    /** https://github.com/aimmanager/gargamel-api-docs/blob/main/Services/Auth.md#get-current-organization */
    getCurrentOrganization(): Promise<FrontOrganization>;

    /** https://github.com/aimmanager/gargamel-api-docs/blob/main/Services/Auth.md#get-organization-by-subdomain
     *
     * Returns null if there is no organization matching the subdomain. */
    getOrganizationBySubdomain: ServiceQuery<[subdomain: string], FrontOrganization | null>;
}

/** https://github.com/aimmanager/gargamel-api-docs/blob/main/Services/Auth.md#organization */
export type BackendOrganization = {
    id: number;
    name: string;
    subdomain: string;
    firebase_tenant_id: string | null;
    sign_in_methods: BackendSignInMethod[];
    // Currently (2024-07-17) not present on backend
    logo_url?: string | null;
};
export function sOrganization(): Describe<BackendOrganization> {
    return type({
        id: integer(),
        subdomain: string(),
        name: string(),
        firebase_tenant_id: nullable(string()),
        sign_in_methods: array(sSignInMethod()),
        logo_url: optional(nullable(string())),
    });
}

export type FrontOrganization = {
    id: string;
    name: string;
    subdomain: string;
    firebaseTenantId: string | null;
    signInMethods: FrontSignInMethod[];
    logoUrl: string;
};

export function deserializeOrganization(org: BackendOrganization): FrontOrganization {
    const featureFlags: Record<SignInMethodType, boolean> = {
        [SignInMethodType.GOOGLE]: parsedEnv.VITE_FEATURE_SIGN_IN_WITH_GOOGLE,
        [SignInMethodType.MAGIC_LINK]: parsedEnv.VITE_FEATURE_SIGN_IN_WITH_MAGIC_LINK,
        [SignInMethodType.OIDC]: parsedEnv.VITE_FEATURE_SIGN_IN_WITH_OIDC,
        [SignInMethodType.PASSWORD]: parsedEnv.VITE_FEATURE_SIGN_IN_WITH_PASSWORD,
        [SignInMethodType.SAML]: parsedEnv.VITE_FEATURE_SIGN_IN_WITH_SAML,
        [SignInMethodType.UNKNOWN]: true,
    };

    return {
        id: String(org.id),
        name: org.name,
        subdomain: org.subdomain,
        logoUrl: org.logo_url ?? "",
        firebaseTenantId: org.firebase_tenant_id || null,
        signInMethods: org.sign_in_methods
            .map(deserializeSignInMethod)
            .filter(m => featureFlags[m.type]),
    };
}

export function deserializeSignInMethod(method: BackendSignInMethod): FrontSignInMethod {
    return parseFirebaseSignInMethod(method.name);
}

export function parseFirebaseSignInMethod(methodName: string): FrontSignInMethod {
    if (methodName === FirebaseSignInMethod.GOOGLE) return { type: SignInMethodType.GOOGLE };

    if (methodName === FirebaseSignInMethod.EMAIL_LINK)
        return { type: SignInMethodType.MAGIC_LINK };

    if (methodName === FirebaseSignInMethod.EMAIL_PASSWORD)
        return { type: SignInMethodType.PASSWORD };

    if (methodName.startsWith("saml")) {
        return { type: SignInMethodType.SAML, providerId: methodName };
    }

    if (methodName.startsWith("oidc")) {
        return { type: SignInMethodType.OIDC, providerId: methodName };
    }

    return { type: SignInMethodType.UNKNOWN, name: methodName };
}
