import { ParentProps } from "solid-js";

export function H1(props: ParentProps<{ class?: string }>) {
    return (
        <h1 class={props.class + " mb-4 text-display-sm sm:text-display-md"}>{props.children}</h1>
    );
}

export function H2(props: ParentProps<{ class?: string }>) {
    return (
        <h2 class={props.class + " mb-2 text-sm font-bold lg:text-default"}>{props.children}</h2>
    );
}

export function Hr(props: ParentProps) {
    return (
        <div class="relative my-4 w-full bg-inherit">
            <hr class="text-black/20" />
            <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-inherit px-4">
                {props.children}
            </div>
        </div>
    );
}

/** A mailto link that opens in a new tab.
 * Note that Firefox opens it in the same tab due to a bug, see
 * https://bugzilla.mozilla.org/show_bug.cgi?id=646552.
 */
export function Mail(props: { to: string }) {
    return (
        <a href={`mailto:${props.to}`} target="_blank" class="text-primary-500">
            {props.to}
        </a>
    );
}

export function P(
    props: ParentProps<{
        class?: string;
        classList?: {
            [k: string]: boolean | undefined;
        };
    }>,
) {
    return (
        <p class={props.class} classList={props.classList}>
            {props.children}
        </p>
    );
}

export function Strong(props: ParentProps) {
    return <strong class="font-bold">{props.children}</strong>;
}

export function Code(props: ParentProps) {
    return <code class="rounded-2 bg-black/10 px-2 text-[0.9rem]">{props.children}</code>;
}
