import { Accessor, ParentProps, createContext, createSignal } from "solid-js";
import { useInterval, useRequiredContext } from "./solidjs";

export interface Clock {
    nowInstant: Accessor<Temporal.Instant>;
    nowZdt: Accessor<Temporal.ZonedDateTime>;
    today: Accessor<Temporal.PlainDate>;
}

const ClockContext = createContext<Clock>();

export function ClockProvider(props: ParentProps) {
    const [nowInstant, setNowInstant] = createSignal(Temporal.Now.instant());
    const [nowZdt, setNowZdt] = createSignal(Temporal.Now.zonedDateTimeISO());
    const [today, setToday] = createSignal(Temporal.Now.plainDateISO());

    useInterval(() => {
        setNowInstant(Temporal.Now.instant());
        setNowZdt(Temporal.Now.zonedDateTimeISO);

        const now = Temporal.Now.plainDateISO();
        // Check if day changed to avoid unnecessary re-renders.
        if (!now.equals(today())) setToday(now);
    }, 200);

    return (
        <ClockContext.Provider value={{ nowInstant, nowZdt, today }}>
            {props.children}
        </ClockContext.Provider>
    );
}

/** Reactive versions of Temporal.Now. */
export function useClock(): Clock {
    return useRequiredContext(ClockContext, "useClock", "ClockProvider");
}
