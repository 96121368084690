import { nonCheckboxInputStyling } from "../presentation";
import { JSX, createUniqueId } from "solid-js";
import { ValidationIconWrapper } from "./parts/icons";
import { LabelAbove } from "./parts/layouts";
import { getCommonInputProps, getInputValidationProps } from "./parts/props";
import { createField, useFormState } from "../state";
import { FieldProps } from "./parts/types";

export type StringFieldProps = FieldProps<string, HTMLInputElement> & {
    type: JSX.IntrinsicElements["input"]["type"];
    inputMode?: JSX.IntrinsicElements["input"]["inputMode"];
    format?: (value: string) => string;
    validate?: (value: string) => true | string;
    /** If provided, a custom message to show when this field is blank, instead
     * of a generic "This field is required". */
    requiredMessage?: string;
    serialize?: (value: string) => string;
    value?: string | number;
    inputClass?: string;
    inputParentClass?: string;
};

export function StringField(props: StringFieldProps) {
    const form = useFormState();
    const field = createField(props, "");
    const inputId = createUniqueId();

    return (
        <LabelAbove {...props} field={field} inputId={inputId}>
            <ValidationIconWrapper field={field} inputParentClass={props.inputParentClass}>
                <input
                    type={props.type}
                    inputMode={props.inputMode}
                    {...getCommonInputProps(inputId, props)}
                    {...getInputValidationProps(field, props)}
                    class={
                        nonCheckboxInputStyling(form.state.enabledInputClass()).class +
                        " " +
                        props.inputClass
                    }
                    style={nonCheckboxInputStyling(form.state.enabledInputClass()).style}
                    value={props.value ?? field.value}
                    onChange={e => props.onChange?.(e)}
                />
            </ValidationIconWrapper>
        </LabelAbove>
    );
}
