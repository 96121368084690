import { mergeProps, ParentProps, Suspense } from "solid-js";
import { NavbarLogoOnly } from "./components";
import { GenericSuspenseFallback } from "./skeletons";

export function PageWrapper(
    props: ParentProps<{
        padding?: "default" | "none";
        class?: string;
    }>,
) {
    const merged = mergeProps({ padding: "default" }, props);

    return (
        <div
            class={`"bg-layout lg:bg-white" ${props.class}`}
            classList={{ "px-4 py-2 lg:px-8 lg:py-8": merged.padding === "default" }}
        >
            <Suspense fallback={<GenericSuspenseFallback />}>{props.children}</Suspense>
        </div>
    );
}

export function PublicPageWithNavbarWrapper(props: ParentProps) {
    return (
        <div class="min-h-screen bg-layout">
            <NavbarLogoOnly />
            <main id="main" class="center-items px-4 py-4 lg:py-8">
                {props.children}
            </main>
        </div>
    );
}
