import { useLocale } from "../modules/i18n/context";
import { parsedEnv } from "./parsedEnv";

export default function VersionNumber() {
    const [locale] = useLocale();

    return (
        <div class="text-xs text-dark-gray-400">
            {locale().menu.version} {parsedEnv.VITE_PACKAGE_JSON_VERSION}
        </div>
    );
}
