import { Interceptor, urlJoin } from "../modules/client/client";
import { parsedEnv } from "../utils/parsedEnv";
import { getSubdomain } from "./SubdomainProvider";
import isNetworkError from "is-network-error";
import { isAuthenticated, revokeSession } from "../modules/auth/authContext";

let successCount = 0;

// Having the API Gateway logic in an interceptor decouples it from the `Client` class.
/** Decorates/wraps an interceptor, so it uses the API Gateway if needed. */
export function apiGateway(interceptor: Interceptor): Interceptor {
    return async (request, fetchData, originalResource) => {
        const subdomain = await getSubdomain();
        const apiGatewayUrl = getApiGatewayUrl(originalResource, subdomain);
        if (apiGatewayUrl !== originalResource)
            request = await cloneRequestWithNewUrl(request, apiGatewayUrl);

        try {
            const data = await interceptor(request, fetchData, originalResource);
            successCount++;
            return data;
        } catch (err) {
            if (
                isNetworkError(err) &&
                navigator.onLine &&
                parsedEnv.VITE_API_GATEWAY &&
                successCount === 0
            ) {
                // Probably the subdomain doesn't exist
                if (await isAuthenticated()) return revokeSession();
                else throw err;
            } else throw err;
        }
    };
}

export function getApiGatewayUrl(originalResource: string, subdomain: string): string {
    // Prepend VITE_API_GATEWAY to the URL unless the request is already being made to an absolute URL.
    const isRelativeUrl = !originalResource.startsWith("http"); // Note that https also starts with http.
    if (isRelativeUrl) {
        // Dirty hack as mycompany is not available in API Gateway
        if (subdomain === "mycompany") subdomain = "tiendasneto";

        return urlJoin(
            (parsedEnv.VITE_API_GATEWAY || "").replaceAll("{subdomain}", subdomain),
            originalResource,
        );
    } else return originalResource;
}

// Changing the URL of a request is absurdly difficult. Note that `request.url = newUrl` doesn't work.
async function cloneRequestWithNewUrl(request: Request, newUrl: string): Promise<Request> {
    if (request.bodyUsed) request = request.clone();

    const body = await request.blob();
    return new Request(
        newUrl,
        body.size > 0
            ? {
                  // for some reason `{ ...request, body }` doesn't work correctly
                  body,
                  method: request.method,
                  headers: request.headers,
                  mode: request.mode,
                  credentials: request.credentials,
                  cache: request.cache,
                  redirect: request.redirect,
                  referrer: request.referrer,
                  integrity: request.integrity,
              }
            : request,
    );
}
