import { Accessor } from "solid-js";
import { createApiQuery } from "../../utils";

export function createOrganizationBySubdomainQuery(subdomain: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["organizationBySubdomain", subdomain()],
        queryFn: () => api.organization.getOrganizationBySubdomain.fetchJson(subdomain()),
        select: api.organization.getOrganizationBySubdomain.select,
    }));
}
export function createCurrentOrganizationQuery() {
    return createApiQuery(api => ({
        queryKey: ["currentOrganization"],
        queryFn: () => api.organization.getCurrentOrganization(),
    }));
}
