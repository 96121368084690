import { createSignal, JSX, Show } from "solid-js";
import { Overlay } from "./SlidingMenu";
import { useLocale } from "../i18n/context";
import { A } from "@solidjs/router";
import { mapValues } from "../../utils/miniLodash";
import { menuTabs } from "./utils";
import { OnClickButton } from "../../utils/solidjs";
import { P } from "../../utils/typography";

export default function MobileFooter() {
    const [expanded, setExpanded] = createSignal(false);

    return (
        <Show
            when={expanded()}
            fallback={<CollapsedFooter onClickPlusButton={() => setExpanded(true)} />}
        >
            <ExpandedFooter onClose={() => setExpanded(false)} />
        </Show>
    );
}

const collapsedFooterPx = "0.5rem";
const collapsedFooterPy = "0.25rem";
const plusButtonPx = "0";
const plusButtonPy = "0.5rem";

function CollapsedFooter(props: { onClickPlusButton?: () => void }) {
    return (
        <nav
            class="sticky bottom-0 left-0 right-0 flex items-center border-t bg-white text-light-gray-300"
            style={{
                padding: `${collapsedFooterPy} ${collapsedFooterPx}`,
            }}
        >
            <div class="flex h-full flex-[2_2_0%] items-center">
                <Tabs1And2 />
            </div>
            <div class="center-items flex-1 text-dark-gray-400">
                <PlusButton onClick={props.onClickPlusButton} />
            </div>
            <div class="flex h-full flex-[2_2_0%] items-center">
                <Tabs3And4 />
            </div>
        </nav>
    );
}

function ExpandedFooter(props: { onClose?: () => void }) {
    //const tabs = useTabs();

    return (
        <Overlay collapsed={false} onClick={props.onClose}>
            <div class="fixed bottom-0 left-0 right-0">
                <div
                    onClick={event => event.stopPropagation()}
                    class="mx-3 flex flex-wrap rounded-xl bg-white"
                    style={{
                        "margin-bottom": `calc(${collapsedFooterPy} + ${plusButtonPy} + ${diameter} / 2)`,
                    }}
                >
                    <div class="relative grid w-full grid-cols-4 gap-3 px-3 pb-4 pt-3">
                        <P class="col-span-4">Aquí habrían más pestañas, si estuvieran hechas...</P>
                        {/*{tabs.linksOfInterest()}*/}
                        {/*{tabs.assets()}*/}
                        {/*{tabs.reports()}*/}
                        {/*{tabs.empty()}*/}
                        <Tabs1And2 onClick={props.onClose} />
                        <Tabs3And4 onClick={props.onClose} />
                        <div class="absolute bottom-0 left-1/2">
                            <div class="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                                <CloseButton onClick={props.onClose} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Overlay>
    );
}

function Tabs1And2(props: { onClick?: () => void }) {
    const tabs = useTabs(props);
    return (
        <>
            {tabs.home()}
            {tabs.activities()}
        </>
    );
}

function Tabs3And4(props: { onClick?: () => void }) {
    const tabs = useTabs(props);
    return (
        <>
            {tabs.checklists()}
            {tabs.posts()}
        </>
    );
}

const diameter = "2.5rem";

function PlusButton(props: { onClick?: OnClickButton }) {
    return (
        <button
            class="center-items h-full w-full"
            style={{ padding: `${plusButtonPy} ${plusButtonPx}` }}
            onClick={e => props.onClick?.(e)}
        >
            <div
                class="center-items border-dark-gray-400"
                style={makeCircleWithOutline(diameter, "1px")}
            >
                <i class="fas fa-plus" />
            </div>
        </button>
    );
}

function CloseButton(props: { onClick?: OnClickButton }) {
    return (
        <button
            class="center-items border-dark-gray-500 bg-white"
            style={makeCircleWithOutline(diameter, "4px")}
            onClick={e => props.onClick?.(e)}
        >
            <i class="fas fa-times text-dark-gray-400" />
        </button>
    );
}

function makeCircleWithOutline(
    diameter: JSX.CSSProperties["width"],
    outlineWidth: JSX.CSSProperties["outline-width"],
) {
    // This is the simplest workaround that renders correctly in Safari (tested in 2023)
    return {
        // Use border because outline is broken in Safari
        "border-width": outlineWidth,
        "border-style": "solid",
        "border-radius": "50%",
        // Make space for the border as it is drawn inside the circle
        width: `calc(${diameter} + ${outlineWidth} * 2)`,
        height: `calc(${diameter} + ${outlineWidth} * 2)`,
        // Workaround so the outline doesn't affect the layout
        margin: `calc(${outlineWidth} * -1)`,
    } satisfies JSX.CSSProperties;
}

function useTabs(options: {
    onClick?: () => void;
}): Record<keyof typeof menuTabs | "empty", () => JSX.Element> {
    const [locale] = useLocale();
    const t = () => locale().menu.tabs;

    return {
        ...mapValues(menuTabs, ({ href, icon }, key) => {
            return () => (
                <FooterTab href={href} icon={icon} title={t()[key]} onClick={options.onClick} />
            );
        }),
        empty: () => <div />,
    };
}

function FooterTab(props: { href: string; icon: string; title: string; onClick?: () => void }) {
    return (
        <A
            class="center-items h-16 min-w-0 flex-1 flex-col gap-1"
            activeClass="text-primary-500"
            inactiveClass="text-dark-gray-400"
            href={props.href}
            replace
            onClick={props.onClick}
        >
            <i class={`${props.icon} text-md`} />
            <div class={"h-text-xs" /* Keep tabs vertically aligned even if text overflows */}>
                <div class="text-center text-xs leading-none">{props.title}</div>
            </div>
        </A>
    );
}
