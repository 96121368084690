import { createSignal, JSX, ParentProps, Show, Suspense } from "solid-js";
import useDoubleShiftListener from "../../utils/useDoubleShiftListener";
import { useLocale } from "../i18n/context";

export function DevSuspense(props: ParentProps<{ fallback: JSX.Element }>) {
    const [forceFallback, setForceFallback] = createSignal(false);
    useDoubleShiftListener(() => setForceFallback(x => !x));

    return (
        <Show when={!forceFallback()} fallback={props.fallback}>
            <Suspense fallback={props.fallback}>{props.children}</Suspense>
        </Show>
    );
}

export function GenericSuspenseFallback() {
    const [locale] = useLocale();
    return <div class="center-items h-full">{locale().utils.loading}...</div>;
}

export function ButtonSkeleton() {
    return <div class="skeleton mb-4 h-10 rounded-md" />;
}

export function HeadingSkeleton() {
    return <div class="skeleton mb-4 h-text-display-xs rounded-xs" />;
}

export function InputSkeleton() {
    return (
        <div>
            <div class="skeleton mb-1 h-text-default w-[7rem] rounded-xs" />
            <div class="skeleton mb-4 h-12 rounded-md" />
        </div>
    );
}

export function LineSkeleton(props: ParentProps) {
    return (
        <div class="skeleton mb-4 h-text-default rounded-xs">
            <div class="invisible">{props.children}</div>
        </div>
    );
}
