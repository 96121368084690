import { errorMessageClass, invalidColorClass, staleColorClass } from "../../presentation";
import { FieldController } from "../../state";

export function ErrorMessage(props: { field: FieldController<unknown>; class?: string }) {
    return (
        <span
            class={`${errorMessageClass} ${
                props.field.lastValidation?.stale ? staleColorClass : invalidColorClass
            } ${props.class}`}
        >
            {props.field.lastValidation?.error}
        </span>
    );
}
