import { createEffect, onCleanup, ParentProps } from "solid-js";
import { OnClickDiv } from "../../utils/solidjs";

const menuToggleDuration = "0.3s";

export default function SlidingMenu(
    props: ParentProps<{
        collapsed: boolean;
        navigationDrawerWidth: string;
        onClick?: OnClickDiv;
    }>,
) {
    return (
        <div
            onClick={e => props.onClick?.(e)}
            style={{
                transition: `width ${menuToggleDuration} ease`,
                "--navigation-drawer-width": props.navigationDrawerWidth,
                width: props.collapsed ? "0" : `var(--navigation-drawer-width)`,
                height: "100%",
                //overflow: "hidden",
                position: "relative",
            }}
        >
            <div
                style={{
                    position: "absolute",
                    transition: `left ${menuToggleDuration} ease`,
                    left: props.collapsed ? `calc(var(--navigation-drawer-width) * -1)` : "0",
                    width: `var(--navigation-drawer-width)`,
                    top: "0",
                    bottom: "0",
                }}
            >
                {props.children}
            </div>
        </div>
    );
}

export function Overlay(
    props: ParentProps<{
        collapsed: boolean;
        onClick?: OnClickDiv;
        animated?: boolean;
        class?: string;
    }>,
) {
    createEffect(() => {
        document.body.style.overflow = props.collapsed ? "auto" : "hidden";
    });
    onCleanup(() => {
        document.body.style.overflow = "auto";
    });

    return (
        <div
            style={{
                position: "fixed",
                inset: "0",
                "background-color": `rgba(0, 0, 0, ${props.collapsed ? 0 : 0.5})`,
                transition: props.animated ? `background-color ${menuToggleDuration} ease` : "none",
                "pointer-events": props.collapsed ? "none" : "auto",
            }}
            class={props.class}
            onClick={e => props.onClick?.(e)}
        >
            {props.children}
        </div>
    );
}
