import jsonpack from "jsonpack";
import { gaussianRandom, sleep } from "../../utils/mocks";
import { Json } from "../../api/utils";

const { min, max } = Math;

export async function fetchDataFromMockBackend(request: Request): Promise<Json> {
    const cache = await cachePromise;
    const responseData = cache[getRequestKey(request)];
    if (responseData === undefined)
        throw new Error(`No mock data for ${request.method} ${request.url}`);
    let delay = gaussianRandom(250, 115);
    delay = min(max(delay, 0), 1000);
    await sleep(delay);
    return responseData;
}

const cachePromise = loadCache();

async function loadCache(): Promise<Record<string, Json>> {
    const packed = (await import("./backendMocks.jsonpack.txt?raw")).default;
    return jsonpack.unpack(packed);
}

function getRequestKey(request: Request): string {
    const words = [request.method, request.url.split("/api")[1]];
    if (request.body) {
        words.push(JSON.stringify(request.body));
    }
    return words.join(" ");
}
