import { FirebaseMessaging, Notification } from "@capacitor-firebase/messaging";
import { createEffect, onCleanup, onMount } from "solid-js";
import { PushNotificationUnion, sPushNotification } from "./PushNotificationsTypes";
import { create, StructError } from "superstruct";
import { useNavigate } from "@solidjs/router";
import { useQueryClient } from "@tanstack/solid-query";
import { InboxNotification, NotificationType } from "./frontendModels";
import { getApiInstance } from "../../api";
import MockNotificationsService from "../../api/services/notifications/implementations/mock";
import { Device } from "@capacitor/device";
import { useAuth } from "../auth/authContext";
import {
    createRegisterDeviceMutation,
    createUnregisterDeviceMutation,
} from "../../api/services/notifications/mutations";
import { Capacitor } from "@capacitor/core";
import { parsedEnv } from "../../utils/parsedEnv";

export function usePushNotificationsRegistration() {
    const registerDeviceMutation = createRegisterDeviceMutation();
    const unregisterDeviceMutation = createUnregisterDeviceMutation();
    const auth = useAuth();

    /* This effect registers the device for notifications when user signs in,
     * and unregisters it when that user signs out. */
    createEffect(() => {
        const user = auth.user();
        if (!user) return;

        // The user just signed in, therefore register this device for push notifications.
        const registeredTokenPromise = Promise.all([
            Device.getInfo(),
            FirebaseMessaging.getToken(
                Capacitor.isNativePlatform() ? {} : { vapidKey: parsedEnv.VITE_VAPID_KEY },
            ),
        ]).then(async ([info, { token }]) => {
            await registerDeviceMutation.mutateAsync({ ...info, token });
            return token;
        });

        // The user just signed out, therefore unregister from push notifications.
        onCleanup(() => {
            /* Await the promise first in case the user signed out really fast,
             * so it doesn't happen that registerDevice completes after unregister. */
            registeredTokenPromise.then(unregisterDeviceMutation.mutate);
        });
    });
}

export function usePushNotificationsAction() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const addNotificationOnce = (notification: PushNotificationUnion) => {
        const api = getApiInstance();
        const notificationOverview: InboxNotification = {
            id: notification.postId,
            title: notification.title,
            createdAt: Temporal.Now.instant(),
            read: false,
            type: NotificationType.POST,
        };
        if (api.notifications instanceof MockNotificationsService) {
            api.notifications.addNotificationOnce(notificationOverview);
            queryClient.refetchQueries({
                queryKey: ["inbox"],
            });
        }
    };
    onMount(() => {
        FirebaseMessaging.addListener("notificationActionPerformed", event => {
            const notification = parsePushNotification(event.notification);
            if (notification === null) return;
            addNotificationOnce(notification);
            switch (notification.type) {
                case "NEW_POST":
                    navigate(`/posts/${notification.postId}`);
                    break;
            }
        });
        FirebaseMessaging.addListener("notificationReceived", event => {
            const notification = parsePushNotification(event.notification);
            if (notification === null) return;
            addNotificationOnce(notification);
        });
    });
}

function parsePushNotification(notification: Notification): PushNotificationUnion | null {
    try {
        return create(notification.data, sPushNotification());
    } catch (error) {
        if (error instanceof StructError) {
            console.error(error);
            return null;
        } else throw error;
    }
}
