import { create, Struct } from "superstruct";
import { Json, queryClient } from "../api/utils";
import { Preferences } from "@capacitor/preferences";
import { createMutation, createQuery } from "@tanstack/solid-query";

export default function JsonStorage<T extends Json>(key: string, schema: Schema<T>) {
    const queryKey = ["JsonStorage", key];

    function useQuery(defaultValue: T) {
        return createQuery(() => ({
            queryKey,
            queryFn: async () => {
                const value = await get();
                return value !== undefined ? value : defaultValue;
            },
        }));
    }

    function useMutation() {
        return createMutation(() => ({
            networkMode: "always",
            mutationKey: queryKey,
            mutationFn: async (newValue: T | null) => {
                await set(newValue ?? undefined);
            },
            onMutate: async (newValue: T | null) => {
                await queryClient.cancelQueries({ queryKey });
                const prevValue = queryClient.getQueryData<T | null>(queryKey);
                queryClient.setQueryData(queryKey, newValue);
                return { prevValue };
            },
            onError: (_err, _newValue, ctx) => {
                queryClient.setQueryData(queryKey, ctx!.prevValue);
            },
            onSettled: () => {
                queryClient.invalidateQueries({ queryKey });
            },
        }));
    }

    async function get(): Promise<T | undefined> {
        try {
            const result = await Preferences.get({ key });
            return create(JSON.parse(result.value!), schema);
        } catch (_) {
            return undefined;
        }
    }

    async function set(
        value: T | undefined | ((prev: T | undefined) => T | undefined),
    ): Promise<void> {
        if (typeof value === "function") value = value(await get());
        if (value === undefined) await Preferences.remove({ key });
        else await Preferences.set({ key, value: JSON.stringify(value) });
    }

    return { useQuery, useMutation, get, set, key };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Schema<T> = Struct<T, any>;
