// Minimal lodash-like functions to optimize bundle size

export function mapValues<O extends Record<string, unknown>, K extends keyof O, U>(
    obj: O,
    fn: (value: O[K], key: K) => U,
): { [P in K]: U } {
    // for is faster than reduce or map https://www.measurethat.net/Benchmarks/Show/11728/0/reduce-vs-mapfromentries-vs-for-loop
    const acc = {} as { [P in K]: U };
    for (const [key, value] of Object.entries(obj) as [K, O[K]][]) {
        acc[key] = fn(value, key);
    }
    return acc;
}

/** Like Python's range function. Example: range(1, 4) is [1, 2, 3]. */
export function range(start: number, stop: number, step: number = 1): number[] {
    if (step === 0) throw new Error("Step can't be 0");
    const ans: number[] = [];
    for (let i = start; Math.sign(stop - i) === Math.sign(step); i += step) {
        ans.push(i);
    }
    return ans;
}
