import { integer, string, Struct, type } from "superstruct";
import { SignInMethod as FirebaseSignInMethod } from "@firebase/auth";

/* Define a new FrontSignInMethod type instead of using BackendSignInMethod
 * so we can leverage TypeScript to ensure we do the sign-in method dispatch correctly.
 */
export type FrontSignInMethod = FederatedSignInMethod | EmailSignInMethod;
// Federated means using a third party authentication provider to sign in
export type FederatedSignInMethod =
    | { type: SignInMethodType.GOOGLE }
    | { type: SignInMethodType.SAML; providerId: string }
    | { type: SignInMethodType.OIDC; providerId: string }
    /* The unknown case goes here as it is more probable that a future version
     * adds a new federated method than an email-based one. */
    | { type: SignInMethodType.UNKNOWN; name: string };
export type EmailSignInMethod =
    | { type: SignInMethodType.MAGIC_LINK }
    | { type: SignInMethodType.PASSWORD };

export enum SignInMethodType {
    GOOGLE = "GOOGLE",
    MAGIC_LINK = "MAGIC_LINK",
    PASSWORD = "PASSWORD",
    SAML = "SAML",
    OIDC = "OIDC",
    UNKNOWN = "UNKNOWN",
}

export function isEmailMethod(method: FrontSignInMethod): method is EmailSignInMethod {
    return method.type === SignInMethodType.MAGIC_LINK || method.type === SignInMethodType.PASSWORD;
}

let autoincrement = 1;

export function mockSignInMethod(type: SignInMethodType): BackendSignInMethod {
    const id = autoincrement++;
    switch (type) {
        case SignInMethodType.GOOGLE:
            return { id, name: FirebaseSignInMethod.GOOGLE };
        case SignInMethodType.MAGIC_LINK:
            return { id, name: FirebaseSignInMethod.EMAIL_LINK };
        case SignInMethodType.PASSWORD:
            return { id, name: FirebaseSignInMethod.EMAIL_PASSWORD };
        case SignInMethodType.SAML:
            return { id, name: "saml.mock" };
        case SignInMethodType.OIDC:
            return { id, name: "oidc.mock" };
        case SignInMethodType.UNKNOWN:
            return { id, name: "unknown.mock" };
    }
}

export type BackendSignInMethod = {
    id: number;
    name: string;
};

export function sSignInMethod(): Struct<BackendSignInMethod> {
    return type({
        id: integer(),
        name: string(),
    });
}

export function describeSignInMethod(method: FrontSignInMethod): string {
    switch (method.type) {
        case SignInMethodType.GOOGLE:
            return "Google";
        case SignInMethodType.MAGIC_LINK:
            return "vínculo mágico";
        case SignInMethodType.PASSWORD:
            return "correo y contraseña";
        case SignInMethodType.SAML:
        case SignInMethodType.OIDC:
            return firstLetterUppercase(method.providerId.split(".")[1]) ?? method.providerId;
        case SignInMethodType.UNKNOWN:
            return "[método desconocido]";
    }
}

function firstLetterUppercase(str: string | undefined): string | undefined {
    return str && str[0].toUpperCase() + str.slice(1);
}
