/* API inspired by React refs to simplify forwarding refs in nested components.
 * This is simpler than using https://www.npmjs.com/package/@solid-primitives/refs. */

// It's both a React ref object and a Solid ref prop (the function part).
export type MutableRefObject<T> = { current: T | undefined } & ((value: T) => void);

/** Examples:
 *
 * ```typescript
 * // Basic ref
 * const ref = createRef<HTMLDivElement>();
 *
 * // Forward ref (nested components)
 * const ref = props.staticRef ?? createRef<HTMLDivElement>();
 * ```
 * */
export function createRef<T>(): MutableRefObject<T> {
    ref.current = undefined as T | undefined;

    function ref(value: T) {
        ref.current = value;
    }

    return ref;
}

export function getOrCreateRef<T>(ref?: MutableRefObject<T>): MutableRefObject<T> {
    return ref ?? createRef<T>();
}
