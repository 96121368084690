const { sqrt, log, cos, PI } = Math;

export function gaussianRandom(average: number, standardDeviation: number): number {
    return average + boxMullerRandom() * standardDeviation;
}

function boxMullerRandom(): number {
    const u = randomFrom0To1BothExclusive();
    const v = randomFrom0To1BothExclusive();
    return sqrt(-2 * log(u)) * cos(2 * PI * v);
}

function randomFrom0To1BothExclusive(): number {
    let value = 0;
    while (value === 0) {
        value = Math.random();
    }
    return value;
}

export async function sleep(milliseconds: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export async function* mockProgress(): AsyncGenerator<number> {
    // Fast start: 0% - 50% in 10 steps
    for (let i = 0; i < 10; i++) {
        yield (i / 10) * 0.5;
        await sleep(30);
    }

    // Slower mid-section: 50% - 80% in 20 steps
    for (let i = 0; i < 20; i++) {
        yield 0.5 + (i / 20) * 0.3;
        await sleep(80);
    }

    // Short pause
    await sleep(800);

    // Fast finish: 80% - 100% in 10 steps (+1 to make sure it reaches 100%)
    for (let i = 0; i <= 10; i++) {
        yield 0.8 + (i / 10) * 0.2;
        await sleep(40);
    }
}
