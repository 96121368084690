import { createEffect, onCleanup } from "solid-js";
import { parsedEnv } from "../../utils/parsedEnv";
import { useAuth } from "../auth/authContext";
import { fetchEventSource, FetchEventSourceInit } from "@microsoft/fetch-event-source";
import { firebaseAuthService } from "../../api/services/auth/implementations/firebase";
import { UnauthorizedError } from "../../api/utils";
import { getApiGatewayUrl } from "../../api/apiGateway";
import { useSubdomain } from "../../api/SubdomainProvider";

export default function useInboxNotificationsListener() {
    const auth = useAuth();
    const [getSubdomain] = useSubdomain();

    /* This effect listens for notifications when user signs in,
     * and stops listening when that user signs out. */
    createEffect(() => {
        const user = auth.user();
        const subdomain = getSubdomain();
        if (!user || !subdomain) return;

        /* The user is signed in, lets listen for inbox notifications through
         * Server Side Events (SSE). */
        const sse = new AuthSSE(
            getApiGatewayUrl(
                `${parsedEnv.VITE_SERVER_NOTIFICATIONS}/notifications/devices/sse`,
                subdomain,
            ),
            async () => ({
                method: "GET",
                headers: {
                    Authorization: `Bearer ${await firebaseAuthService.getIdToken()}`,
                    "Content-Type": "text/event-stream",
                    accept: "text/event-stream",
                },
                onmessage: message => {
                    if (message.event === "ping") {
                        console.debug("Notification ping received");
                    } else {
                        console.debug("Received inbox notification", message);
                    }
                },
                onerror: error => {
                    /* Ignore this error as fetchEventSource will retry the connection
                     * and seems to be a harmless error from the backend. */
                    if (
                        error instanceof TypeError &&
                        error.message.includes("Error in input stream")
                    )
                        return;

                    // Hide for demo
                    //showErrorToast(locale().notifications.inboxNotificationsSseError);
                    console.error("onerror", error, error.error);
                },
            }),
        );

        // The user signed out, therefore stop receiving notifications.
        onCleanup(() => sse.abort());
    });
}

/** Allows connecting to Server Side Events (SSE) with authentication headers,
 * retrying on a 401 error.
 */
class AuthSSE {
    constructor(
        private input: RequestInfo,
        private init: () => Promise<Omit<FetchEventSourceInit, "signal" | "onopen">>,
    ) {
        this.connectRetryingOn401(0);
    }

    abort(): void {
        this.ctrl.abort();
    }

    // New instances of AbortController are created as needed, see https://stackoverflow.com/a/64795615
    private ctrl = new AbortController();

    // A cooldown timer prevents infinite loops if the 401 is not gone
    private connectRetryingOn401(cooldown = 10_000): void {
        const t0 = Date.now();
        this.connectUntil401().catch(error => {
            if (error instanceof UnauthorizedError && Date.now() - t0 > cooldown) {
                this.connectRetryingOn401();
            } else {
                console.error("wtf", error);
            }
        });
    }

    /** Opens an SSE connection. The promise never resolves, but throws on a 401.
     * @throws UnauthorizedError
     */
    private async connectUntil401(): Promise<void> {
        this.ctrl = new AbortController();

        const init = await this.init();
        return new Promise((_resolve, reject) => {
            /* Use Microsoft's version of EventSource, so we can send auth headers.
             * For context, see https://github.com/whatwg/html/issues/2177 */
            fetchEventSource(this.input, {
                ...init,
                signal: this.ctrl.signal,
                onopen: async response => {
                    /* When the token expires, this is the best we can do here.
                     * For context, see https://github.com/Azure/fetch-event-source/issues/33#issuecomment-1517337225 */
                    if (response.status === 401) {
                        console.debug("onopen 401", response);
                        reject(new UnauthorizedError());
                    }
                },
            });
        });
    }
}
