import { Show } from "solid-js";
import { H1, P } from "../../utils/typography";

import { PageWrapper } from "../ui/pageWrappers";

export default function LoremIpsum(props: { title: string; variant: "short" | "long" }) {
    return (
        <PageWrapper>
            <H1>{props.title}</H1>
            <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse facilisis odio
                rutrum, vulputate justo quis, dictum orci. Sed eget mollis nunc. Fusce imperdiet
                egestas sem sit amet venenatis. Mauris volutpat consequat elit, in rhoncus nibh
                eleifend in. Etiam vitae posuere urna. Quisque commodo venenatis ex in vestibulum.
                Donec sit amet bibendum nisi. Mauris at arcu massa. Fusce dui velit, porttitor in
                velit non, suscipit posuere urna. Vestibulum velit nulla, facilisis et nisl iaculis,
                volutpat pulvinar nisl. Nunc fermentum lacinia varius. Fusce lacinia lobortis enim,
                sit amet ultrices odio efficitur non. Integer et felis dolor. Sed lacus odio,
                eleifend a commodo quis, elementum in sapien. Curabitur vel placerat ipsum.
            </P>
            <Show when={props.variant === "long"}>
                <P>
                    Interdum et malesuada fames ac ante ipsum primis in faucibus. Morbi pharetra
                    massa tortor, sed consequat sapien gravida non. Pellentesque tincidunt, arcu ac
                    pharetra dictum, orci elit hendrerit nibh, ultrices dignissim magna velit
                    placerat justo. Curabitur iaculis iaculis dui in congue. Suspendisse a odio a
                    justo bibendum elementum. Nullam at eros iaculis, sodales ante non, molestie
                    ante. Phasellus sit amet lobortis ante. Nullam luctus auctor rhoncus. Vivamus
                    tortor lectus, ornare eu lacus in, pharetra vulputate libero. Mauris tellus
                    erat, porta eu justo vehicula, iaculis dictum sem. Etiam fermentum non augue
                    eget lobortis. Quisque rhoncus porttitor elementum. Class aptent taciti sociosqu
                    ad litora torquent per conubia nostra, per inceptos himenaeos. Nam efficitur est
                    quis lectus convallis aliquam. Aenean viverra, elit at ornare ornare, nulla mi
                    convallis quam, quis finibus nisl risus sit amet turpis.
                </P>
                <P>
                    Duis sed libero libero. Sed vel finibus nisi. Nulla non nisl et augue pharetra
                    viverra a vitae erat. Cras posuere pellentesque risus, in porta diam. Donec
                    elementum, purus hendrerit porta fringilla, sem turpis ultricies lacus, ut
                    vestibulum metus sem sit amet libero. Nam feugiat eu tellus in vulputate. Aenean
                    eu dui vel ex condimentum tristique. Curabitur quis metus at risus facilisis
                    egestas. Sed eget commodo nulla. Phasellus sit amet orci quam. Donec iaculis
                    egestas est, vitae commodo leo. Etiam dignissim tortor id sem maximus, sagittis
                    pharetra ligula dictum.
                </P>
                <P>
                    Fusce in felis rutrum, auctor sem in, faucibus augue. Curabitur quis odio
                    aliquet, condimentum mi eget, volutpat augue. Nulla tristique elementum magna.
                    Curabitur tempor fermentum vestibulum. Sed condimentum quam sit amet enim
                    blandit luctus. Cras porttitor sapien lacinia euismod elementum. Vestibulum
                    rhoncus tempor nunc a laoreet. Pellentesque eu sapien non ex hendrerit pharetra.
                    Vivamus odio turpis, tincidunt id laoreet in, egestas eget magna. Morbi eu nulla
                    vitae neque sodales mattis at non risus. Phasellus scelerisque neque quis tellus
                    malesuada convallis. Etiam rutrum interdum nunc at pretium. Sed ornare laoreet
                    nulla, a tempus massa. Mauris vehicula suscipit mauris, sit amet lobortis sapien
                    dignissim at. Nam posuere, lacus non consequat ullamcorper, lectus nibh lobortis
                    justo, eu pulvinar est enim a dolor. Integer finibus neque et lectus mattis
                    finibus.
                </P>
                <P>
                    Aliquam massa ante, mollis id convallis et, elementum eget risus. Etiam a nulla
                    eu sapien pharetra tincidunt sit amet ac leo. Interdum et malesuada fames ac
                    ante ipsum primis in faucibus. Sed posuere quam a enim cursus rutrum. Maecenas
                    facilisis ipsum at mollis pretium. Aliquam sit amet laoreet dui. Nunc dictum leo
                    malesuada lacus bibendum laoreet. Vestibulum ut cursus purus. In hac habitasse
                    platea dictumst.
                </P>
                <P>
                    Ut sed posuere enim, eu porta felis. Maecenas nec consectetur urna, non porta
                    massa. Nunc sapien nisl, tempor in purus consequat, ullamcorper ornare lacus.
                    Sed pellentesque aliquam orci eu porta. Pellentesque laoreet et velit vitae
                    scelerisque. Praesent ac tincidunt justo, id interdum ante. Phasellus finibus
                    posuere ullamcorper. Praesent lobortis nisl vitae arcu ornare, venenatis dictum
                    nulla finibus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
                    posuere cubilia curae; Mauris pellentesque magna quis mi luctus, in consequat
                    odio volutpat. Phasellus id mauris sed elit sodales ullamcorper pretium eget
                    ligula. Ut volutpat nulla ut auctor scelerisque. Fusce varius pretium mauris, a
                    pretium mi cursus non. Sed ligula erat, ultrices vel leo hendrerit, imperdiet
                    aliquet ipsum.
                </P>
                <P>
                    Quisque fringilla lorem sed euismod cursus. Sed suscipit justo ligula, eget
                    tempor ipsum ornare vitae. Mauris quis nisl porttitor, feugiat est id, finibus
                    libero. Sed malesuada interdum elit, vitae semper nisi dignissim non. Curabitur
                    hendrerit tincidunt enim in consequat. Donec vel dictum mi. Mauris orci elit,
                    luctus eget turpis aliquam, semper sagittis sem. Nullam lacinia luctus elit id
                    ultricies. Maecenas mattis ullamcorper turpis, in tempus eros venenatis nec.
                    Mauris a lacinia libero. Fusce aliquet quam et odio aliquam condimentum.
                </P>
                <P>
                    Nunc laoreet odio justo, nec tristique elit feugiat eget. Aliquam laoreet ante
                    id molestie commodo. Vivamus sed risus vel magna accumsan fermentum. Nulla
                    lobortis facilisis tincidunt. Ut venenatis, metus eu sollicitudin condimentum,
                    tortor magna bibendum augue, ut pharetra dui orci eget ligula. Nam vehicula
                    rhoncus felis sit amet convallis. Nam laoreet consequat leo vitae aliquam.
                </P>
                <P>
                    Suspendisse suscipit, libero quis rhoncus venenatis, felis nisi placerat tellus,
                    nec dictum massa magna eleifend elit. Etiam nec dolor vitae diam interdum
                    pharetra. Praesent dictum dignissim nisi, non faucibus turpis gravida nec.
                    Maecenas eleifend sapien ligula. Nullam placerat tincidunt libero scelerisque
                    tincidunt. Curabitur nec libero ultricies, dignissim orci eu, congue libero. Nam
                    pretium vehicula magna, vitae scelerisque eros ultrices porta. Nunc orci metus,
                    varius eu nulla ac, cursus rhoncus justo. In gravida vestibulum nisi congue
                    iaculis. Aenean at pretium elit.
                </P>
                <P>
                    Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
                    turpis egestas. Ut vel porttitor libero. Sed volutpat quam et aliquam finibus.
                    Phasellus tellus lectus, convallis vitae ex ut, porttitor consectetur neque.
                    Pellentesque eu ante consequat, fringilla turpis ac, varius magna. Fusce luctus
                    luctus urna sed convallis. Maecenas vel pretium tellus, et eleifend urna.
                    Quisque gravida in ante eu commodo. Praesent condimentum nisl sit amet orci
                    euismod cursus. Aenean bibendum, nibh ut eleifend fringilla, nibh felis bibendum
                    justo, eget dictum ligula quam ac diam. Phasellus et dolor lacinia, tristique
                    odio ut, ullamcorper sem. Ut commodo, nibh nec tincidunt condimentum, nisl nisi
                    placerat tortor, sit amet condimentum dolor enim vitae turpis.
                </P>
            </Show>
        </PageWrapper>
    );
}
