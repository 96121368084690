import { Accessor, createEffect, createSignal, onCleanup } from "solid-js";
import { mapValues } from "./miniLodash";

/* Breakpoints from Bootstrap 5.
 * If you change them, also change them in tailwind.config.js */
const breakpoints = {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
};

type Breakpoint = keyof typeof breakpoints;

export function useResponsiveBreakpoints(): Record<Breakpoint, Accessor<boolean>> {
    return mapValues(breakpoints, (_, bp) => useBreakpoint(bp));
}

function useBreakpoint(breakpoint: Breakpoint): Accessor<boolean> {
    return useMediaQuery(`(min-width: ${breakpoints[breakpoint]})`);
}

function useMediaQuery(query: string): Accessor<boolean> {
    const matchMedia = window.matchMedia(query);
    const [matches, setMatches] = createSignal(matchMedia.matches);

    createEffect(() => {
        matchMedia.addEventListener("change", handleChange);
        onCleanup(() => matchMedia.removeEventListener("change", handleChange));
    });

    function handleChange() {
        setMatches(matchMedia.matches);
    }

    return matches;
}
