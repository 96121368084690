import { Locale } from "./Locale";
import { en } from "./locales/en";
import { es } from "./locales/es";

// Locales will appear in this order in the language selector
export const localeByCode = {
    en,
    es,
} satisfies Record<string, Locale>;

export type LanguageCode = keyof typeof localeByCode;
