import { InboxNotification, NotificationType } from "./frontendModels";

const now = Temporal.Now.instant();

export const mockNotifications: InboxNotification[] = [
    {
        id: "1",
        title: "Actividad para Corroboración de RRHH",
        createdAt: now.subtract({ minutes: 5 }),
        read: false,
        type: NotificationType.ACTIVITY,
    },
    {
        id: "2",
        title: "Actividad para Aprobación de supervisor",
        createdAt: now.subtract({ hours: 1 }),
        read: false,
        type: NotificationType.ACTIVITY,
    },
    {
        id: "3",
        title: "Actividad para Confirmar que es llamado el subproceso",
        createdAt: now.subtract({ minutes: 512 }),
        read: true,
        type: NotificationType.ACTIVITY,
    },
    {
        id: "c557e492-e82d-4f72-aa87-952adcc5611b",
        title: "Cambio en Solicitud de Vacaciones",
        createdAt: now.subtract({ minutes: 1024 }),
        read: false,
        type: NotificationType.POST,
    },
    {
        id: "5",
        title: "Lanzamiento versión 1.0.0",
        createdAt: now.subtract({ minutes: 2048 }),
        read: true,
        type: NotificationType.SYSTEM_UPDATE,
    },
    {
        id: "6",
        title: "Reporte mensual - Comunicados",
        createdAt: now.subtract({ minutes: 4096 }),
        read: true,
        type: NotificationType.REPORT,
    },
    {
        id: "7",
        title: "Reporte mensual - Actividades",
        createdAt: now.subtract({ minutes: 8192 }),
        read: true,
        type: NotificationType.REPORT,
    },
];
