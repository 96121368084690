import { CreateInfiniteQueryResult, InfiniteData } from "@tanstack/solid-query";
import { Accessor, For, JSX, Show } from "solid-js";
import { Button } from "./components";
import { useLocale } from "../i18n/context";
import { Paginated, PaginationParams } from "../../api/utils";

export default function InfiniteScroll<TItem, TError>(props: {
    infiniteQuery: CreateInfiniteQueryResult<
        InfiniteData<Paginated<TItem>, PaginationParams>,
        TError
    >;
    noItemsFallback: JSX.Element;
    children: (item: TItem, index: Accessor<number>) => JSX.Element;
}) {
    return (
        <Show when={props.infiniteQuery.data}>
            {infiniteData => (
                <div>
                    <For each={infiniteData().pages} fallback={props.noItemsFallback}>
                        {(page, pageIndex) => (
                            <For each={page.results}>
                                {(item, itemIndexWithinPage) => {
                                    const pageSize = () => infiniteData().pageParams[0].pageSize!;
                                    const itemIndexAcrossPages = () =>
                                        pageIndex() * pageSize() + itemIndexWithinPage();
                                    return <>{props.children(item, itemIndexAcrossPages)}</>;
                                }}
                            </For>
                        )}
                    </For>
                    <SeeMoreButton infiniteQuery={props.infiniteQuery} />
                </div>
            )}
        </Show>
    );
}

function SeeMoreButton<TData, TError>(props: {
    infiniteQuery: CreateInfiniteQueryResult<TData, TError>;
}) {
    const [locale] = useLocale();

    return (
        <Button
            onClick={() => props.infiniteQuery.fetchNextPage()}
            disabled={!props.infiniteQuery.hasNextPage || props.infiniteQuery.isFetchingNextPage}
            disabledReason={
                !props.infiniteQuery.hasNextPage
                    ? locale().notifications.noMoreNotifications
                    : locale().utils.loading
            }
            icon={props.infiniteQuery.isFetchingNextPage ? "fas fa-spinner fa-spin" : undefined}
            bgStyle="text-only"
        >
            {locale().utils.seeMore}
        </Button>
    );
}
