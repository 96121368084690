import { Show } from "solid-js";

export default function Avatar(props: {
    picture: string | null | undefined;
    size: "xl" | "lg" | "md" | "sm";
}) {
    return (
        <Show
            when={props.picture}
            fallback={
                <i
                    class="fas fa-user-circle text-dark-gray-400"
                    style={{ "font-size": sizes[props.size] }}
                />
            }
        >
            {picture => (
                <img
                    src={picture()}
                    alt=""
                    referrerPolicy="no-referrer" // https://stackoverflow.com/a/61042200
                    class="rounded-full"
                    style={{
                        width: sizes[props.size],
                        height: sizes[props.size],
                    }}
                />
            )}
        </Show>
    );
}

const sizes = {
    xl: "3.5rem",
    lg: "2.5rem",
    md: "2rem",
    sm: "1.25rem",
};

export function LoremAvatar(props: { hue: number; name: string | undefined }) {
    const size = "2.5rem";

    return (
        <div
            class="center-items rounded-full text-lg text-white"
            style={{
                "background-color": `hsl(${props.hue}, 100%, 25%)`,
                "font-weight": 500,
                width: size,
                height: size,
            }}
        >
            {props.name
                ?.split(" ", 2)
                .map(name => name[0].toUpperCase())
                .join("")}
        </div>
    );
}
