import { useLocale } from "../../i18n/context";
import { StringField } from "./abstract";

import { FieldProps } from "./parts/types";
export const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export default function EmailField(props: FieldProps<string, HTMLInputElement>) {
    const [l] = useLocale();
    const isEmail = (val: string) => {
        return emailRegex.test(val) || l().auth.invalidEmail;
    };
    return <StringField type="email" {...props} validate={isEmail} />;
}
