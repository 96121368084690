import { SafeArea, SafeAreaInsets } from "capacitor-plugin-safe-area";
import { Capacitor } from "@capacitor/core";
import { ParentProps, Show } from "solid-js";

/** Container that avoids status bars, notches, etc. in mobile devices,
 * ensuring that the content is visible and not overlapped by them. */
export default function SafeAreaWrapper(props: ParentProps) {
    /* We will only need it for Android if we use the translucent status bar, i.e.
     * StatusBar.setOverlaysWebView({ overlay: true }); */
    return (
        <Show when={Capacitor.getPlatform() === "ios"} fallback={props.children}>
            <SafeAreaWrapperIos>{props.children}</SafeAreaWrapperIos>
        </Show>
    );
}

function SafeAreaWrapperIos(props: ParentProps) {
    SafeArea.getSafeAreaInsets().then(updateSafeArea);
    SafeArea.addListener("safeAreaChanged", updateSafeArea);

    function updateSafeArea(data: SafeAreaInsets) {
        for (const [key, value] of Object.entries(data.insets)) {
            document.documentElement.style.setProperty(`--safe-area-${key}`, `${value}px`);
        }
    }

    return (
        <div
            style={{
                position: "absolute",
                top: "var(--safe-area-top)",
                right: "var(--safe-area-right)",
                bottom: "var(--safe-area-bottom)",
                left: "var(--safe-area-left)",
            }}
        >
            {props.children}
        </div>
    );
}
