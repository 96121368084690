import { Paginated, PaginationParams } from "../../utils";
import { InboxNotification } from "../../../modules/notifications/frontendModels";
import { getApiInstance } from "../../index";
import {
    CreateInfiniteQueryResult,
    InfiniteData,
    createInfiniteQuery,
} from "@tanstack/solid-query";

export function createInboxInfiniteQuery(): CreateInfiniteQueryResult<
    InfiniteData<Paginated<InboxNotification>, Required<PaginationParams>>
> {
    const api = getApiInstance();
    return createInfiniteQuery(() => ({
        queryKey: ["inbox"],
        queryFn: ({ pageParam }) => api.notifications.paginateInbox(pageParam),
        initialPageParam: {
            pageNumber: 1,
            pageSize: 20,
        },
        getNextPageParam: (lastPage, _allPages, lastPageParam) => {
            if (lastPage.results.length === 0) {
                return undefined;
            }
            return {
                pageNumber: lastPageParam.pageNumber + 1,
                pageSize: lastPageParam.pageSize,
            };
        },
    }));
}
