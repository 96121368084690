import { useLocale } from "../i18n/context";
import { Button } from "../ui/components";
import { useAuth } from "./authContext";

export default function LogoutButton() {
    const [locale] = useLocale();
    const { logout } = useAuth();

    return (
        <Button variant="danger" bgStyle="text-only" icon="fas fa-sign-out-alt" onClick={logout}>
            {locale().menu.logout}
        </Button>
    );
}
