import {
    Accessor,
    createContext,
    createEffect,
    createRoot,
    onMount,
    ParentProps,
    Resource,
    Setter,
} from "solid-js";
import { AuthenticatedUser, UseAuthReturn } from "./useAuthReturn";
import {
    awaitResource,
    createAsyncState,
    useRequiredContext,
    useRunWithErrorBoundary,
    useWindowEventListener,
} from "../../utils/solidjs";
import "./initializeFirebaseApp";
import { useNavigate } from "@solidjs/router";
import {
    AuthStateChangeListener,
    FirebaseAuthentication,
    User as FirebaseUser,
} from "@capacitor-firebase/authentication";
import { createModalController } from "../ui/Modal";
// import SwitchWorkspaceModal from "./SwitchWorkspaceModal";
import { useLocale } from "../i18n/context";
import { fireAndForget } from "../../utils/async";

import { BackendVersion } from "../../api/services/auth/interface";
import { getApiInstance } from "../../api";

import { UnauthorizedError } from "../../api/utils";
import _ from "lodash";
import { showErrorToast, showInfoToast } from "../../utils/errorHandling";
import { parseFirebaseSignInMethod } from "../../api/services/organization/interface";
import { Locale } from "../i18n/Locale";
import {
    describeSignInMethod,
    SignInMethodType,
} from "../../api/services/organization/signInMethods";

declare global {
    interface WindowEventMap {
        SignIn: CustomEvent<{ user: AuthenticatedUser }>;
        SignOut: CustomEvent;
    }
}

export function dispatchSignInEvent(user: AuthenticatedUser): void {
    window.dispatchEvent(new CustomEvent("SignIn", { detail: { user } }));
}

export function dispatchSignOutEvent(): void {
    window.dispatchEvent(new CustomEvent("SignOut"));
}

/** True if the user is signed in
 *
 * @remarks
 * This is not reactive. See {@link useAuth} for a reactive version.
 */
export async function isAuthenticated(): Promise<boolean> {
    const user = await awaitResource(userResource);
    return user != null;
}

/** Returns the current user. Throws if not signed in.
 *
 * @throws UnauthorizedError
 *
 * @remarks
 * This is not reactive. See {@link useAuth} for a reactive version.
 */
export async function getUserOr401(): Promise<AuthenticatedUser> {
    const user = await awaitResource(userResource);
    if (user === null) throw new UnauthorizedError();
    return user;
}

/** The user that is currently signed in.
 *  - `null`: we know the user is not signed in.
 *  - `undefined`: we don't know yet (authenticator still loading).
 */
let userResource: Resource<AuthenticatedUser | null> = undefined!;
let setUserResource: Setter<AuthenticatedUser | null> = undefined!;
createRoot(() => {
    [userResource, setUserResource] = createAsyncState<AuthenticatedUser | null>();
});

/** Allows `Client` instances to sign out the user on certain backend errors.
 * @returns Promise<never> so you can `return revokeSession()` and the endpoint
 * promise will never resolve nor reject.
 */
export async function revokeSession(): Promise<never> {
    return await _revokeSession();
}

// will be replaced with the actual implementation on AuthProvider
let _revokeSession = () => new Promise<never>(() => {});

const AuthContext = createContext<UseAuthReturn>();

export function AuthProvider(props: ParentProps) {
    useFirebaseInAppLanguage();

    const setUser = (u: AuthenticatedUser | null) => {
        setUserResource(u);
        setAuthenticated(!!u);
    };
    // `createResource(user, user => !!user)` doesn't work here, see https://github.com/solidjs/solid/issues/1864
    const [isAuthenticated, setAuthenticated] = createAsyncState<boolean>();

    useAuthStateChangeListener(({ user: firebaseUser }) => {
        if (firebaseUser) dispatchSignInEvent(adaptUser(firebaseUser));
        else dispatchSignOutEvent();
    });

    const navigate = useNavigate();

    useWindowEventListener("SignIn", (event: CustomEvent<{ user: AuthenticatedUser }>) => {
        setUser(event.detail.user);
        if (
            location.href.endsWith("/sign-in") ||
            location.href.includes("/from-invite") ||
            location.href.includes("/from-magic-link")
        ) {
            navigate("/", { replace: true });
        }
    });

    useWindowEventListener("SignOut", () => {
        setUser(null);
    });

    const switchWorkspaceModal = createModalController<AuthenticatedUser, void>();

    const [locale] = useLocale();

    async function signOut(options: { initiatedByUser: boolean }): Promise<void> {
        const user = userResource();
        if (
            user?.signInMethod &&
            [SignInMethodType.OIDC, SignInMethodType.SAML].includes(user.signInMethod.type) &&
            !confirm(
                `Se cerrará sesión en AIM Manager X, pero seguirá abierta en la página de ${describeSignInMethod(
                    user.signInMethod,
                )}`,
            )
        )
            return;

        await getApiInstance().auth.signOut();

        navigate("/login");
        // we can't do await navigate, so we have to guess here...
        const estimatedNavigateDelay = 200;

        if (!options.initiatedByUser)
            setTimeout(() => alertSessionRevokedOnce(user, locale), estimatedNavigateDelay);
    }

    _revokeSession = async (): Promise<never> => {
        await signOut({ initiatedByUser: false });
        return new Promise<never>(() => {});
    };

    const auth: UseAuthReturn = {
        isAuthenticated,
        user: userResource,
        openLoginPage: async () => navigate("/sign-in", { replace: true }),
        logout: async () => signOut({ initiatedByUser: true }),
        openWorkspaceSwitcher() {
            const u = userResource();
            if (!u) throw new Error("User must be signed in to switch workspace");
            switchWorkspaceModal.open(u);
        },
    };

    return (
        <AuthContext.Provider value={auth}>
            {props.children}
            {/*<SwitchWorkspaceModal controller={switchWorkspaceModal} />*/}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useRequiredContext(AuthContext, "useAuth", "AuthProvider");

function useFirebaseInAppLanguage(): void {
    const [, { languageCode }] = useLocale();

    createEffect(() => {
        fireAndForget(FirebaseAuthentication.setLanguageCode({ languageCode: languageCode() }));
    });
}

function useAuthStateChangeListener(listenerFunc: AuthStateChangeListener): void {
    const runWithErrorBoundary = useRunWithErrorBoundary();

    onMount(() => {
        FirebaseAuthentication.addListener("authStateChange", change => {
            runWithErrorBoundary(() => {
                listenerFunc(change);
            });
        });
    });
}

export function adaptUser(firebaseUser: FirebaseUser): AuthenticatedUser {
    if (!firebaseUser.email) throw new Error("Firebase user has no email");
    return {
        ...firebaseUser, // keep original object for debugging
        id: firebaseUser.uid,
        email: firebaseUser.email,
        name: firebaseUser.displayName ?? firebaseUser.email,
        firebaseTenantId: firebaseUser.tenantId,
        picture: firebaseUser.photoUrl,
        backendVersion: BackendVersion.Atlas,
        lastSignInTime: firebaseUser.metadata.lastSignInTime
            ? Temporal.Instant.fromEpochMilliseconds(firebaseUser.metadata.lastSignInTime)
            : undefined,
        signInMethod:
            firebaseUser.providerData[0] &&
            parseFirebaseSignInMethod(firebaseUser.providerData[0].providerId),
    };
}

// session may be revoked from multiple places at once as every backend service may throw a different auth error
const alertSessionRevokedOnce = _.throttle(
    (user: AuthenticatedUser | null | undefined, locale: Accessor<Locale>) => {
        const wasImmediatelyRevoked =
            user?.lastSignInTime &&
            user.lastSignInTime.until(Temporal.Now.instant()).total("minutes") < 1;

        if (wasImmediatelyRevoked)
            showErrorToast(
                // More useful to the user than "You have a Firebase account but no backend account"
                "Tu sesión ha sido revocada por el sistema, probablemente no perteneces a la organización.",
                { position: "center" },
            );
        else showInfoToast(locale().auth.sessionExpired, { position: "center" });
    },
    30_000,
);
