import { JSX } from "solid-js";

type Styling = {
    class?: string;
    style?: JSX.CSSProperties;
};

const checkboxSize = "1.1rem";
export const checkboxStyling: Styling = {
    class: "m-0",
    style: {
        height: checkboxSize,
        width: checkboxSize,
    },
};

const enabledInputClass =
    "rounded-xs border border-light-gray-300 bg-white placeholder:dark-gray-400 px-4 py-2 w-full";
const disabledFieldClass = "disabled:bg-light-gray-300 disabled:cursor-not-allowed";
const readonlyFieldClass = disabledFieldClass.replaceAll("disabled", "read-only");

export const nonCheckboxInputStyling = (enabledClass: string = enabledInputClass): Styling => ({
    class: `${enabledClass} ${disabledFieldClass} ${readonlyFieldClass}`,
});

export const selectStyling = (enabledClass: string = enabledInputClass): Styling => ({
    class: `${enabledClass} ${disabledFieldClass} w-full`, // read-only:* doesn't work with <select>
    style: { appearance: "none" }, // Safari's default appearance is not customizable
});
/* The custom caret is not only to please the designer, we need it as in Safari the default appearance
 * of <select> is too different to other fields, and `appearance: none` doesn't have a caret. */
export const selectIcon = "fas fa-caret-down text-light-gray-300 text-sm";

export const validationIconSizeClass = "text-sm";
export const validIcon = "fas fa-check";
export const validColorClass = "text-success-500";
export const invalidIcon = "fas fa-times";
export const invalidColorClass = "text-error-500";
export const staleColorClass = "text-dark-gray-400";
export const errorMessageClass = "text-sm"; // color is set in ErrorMessage
