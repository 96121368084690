export function ErrorAlert(props: { text: string; class?: string }) {
    return (
        <div
            class={
                "flex items-center gap-x-2 rounded-md border border-error-200 bg-error-100 p-2 text-error-500 " +
                props.class
            }
        >
            <i class="fas fa-exclamation-triangle text-md text-error-500" />
            <div>{props.text}</div>
        </div>
    );
}
