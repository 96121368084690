import { useLocale } from "../modules/i18n/context";
import { Mail, P } from "./typography";
import { A } from "@solidjs/router";

export default function SupportParagraph() {
    const [locale] = useLocale();
    const t = () => locale().utils.support;

    return (
        <P>
            {t().contactUsAt}
            <Mail to={t().email} />
            {t().or}
            <A href={t().url} target="_blank">
                {t().url}
            </A>
            .
        </P>
    );
}
